// Here you can add other styles
body {
  font-family: 'Open Sans', sans-serif;
  background: #f9fbfc;
}

// custom header color
.cust-header {
  background: #fbfbfb;
  border-bottom: 0;

  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.05);

  button {
    color: #f7636c;
  }

  .nav-item.dropdown {
    a.nav-link {
      color: #f7636c;
    }
  }
}

// sidebar
.cust-sidebar {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  color: #f7636c;
  font-size: 14px;
  font-weight: 600;

  //brand
  .sidebar-brand {
    background: #fbfbfb;
    box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.05);
  }

  // nav
  .sidebar-nav {
    margin-top: 15px;
  }

  //icons
  .nav-icon {
    color: #f7636c;
  }

  .nav-item,
  .nav-link {
    color: #262626;
  

  }

  .nav-item {
    &:hover {
      color: #f7636c;

      //   background: #2856ce;
      .nav-icon {
        color: #f7636c;
      }
    }

    margin: 10px;
  }

  .nav-link {
    padding: 0.8445rem 0.4rem;
    &:hover {
      color: #f7636c;

      //   background: #2856ce;
      .nav-icon {
        color: #f7636c;
      }
    }
  }

  .nav-link.active {
    background: #f7636c;
    /* Dark/Inner II 8% Black 24 Blur */

    box-shadow: inset 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);

    .nav-icon {
      color: #ffffff;
    }
  }
}

// login page

.login-page {
  .login {
    min-height: 100vh;
  }

  .bg-image {
    // background-image: url('https://source.unsplash.com/WEQbe2jBg40/600x1200');
    background-image: url('../assets/images/login-bg-1.png');
    background-size: cover;
    background-position: center;
  }

  .login-heading {
    font-weight: 300;
  }

  .btn-login {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1rem;
    background-color: #0217cd;
  }

  a {
    color: #0217cd;
    text-decoration: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    color: var(--cui-input-focus-color, rgba(44, 56, 74, 0.95)) !important;
    background-color: #fff !important;
  }
}

// custom button theme
.cust-btn-primary {
  background: #f7636c;
  /* Primary/Button */

  box-shadow: 0px 12px 14px rgba(247, 99, 108, 0.05);
  border: 1.5px solid #f7636c;
  border-radius: 100px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  &:hover {
    background: #fff;
    color: #4d4d4d;
    border: 1.5px solid #f7636c;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
    background: #fff;
    color: #4d4d4d;
    border: 1.5px solid #f7636c;
  }

  &:active {
    background: #fff;
    color: #4d4d4d;
    border: 1.5px solid #f7636c;
  }

  &:disabled {
    background: #f7636c;
    border: 1.5px solid #f7636c;
  }
}

.cust-btn-secondary {
  background: #ffffff;
  border: 1.5px solid #f7636c;
  border-radius: 100px;
  box-sizing: border-box;
  box-shadow: 0px 12px 14px rgba(247, 99, 108, 0.05);

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #262626;

  &:hover {
    background: #f7636c;
    border: 1.5px solid #f7636c;
  }

  &:disabled {
    background: #ffffff;
    border: 1.5px solid #f7636c;
    color: #262626;

    &:hover {
      cursor: not-allowed !important;
    }
  }
}
.cust-btn-secondary.disabled{

  background: #ffffff;
  border: 1.5px solid #f7636c;
  color: #262626 !important;
opacity: 0.2;
}
.key-gen-btn {
  color: #f7636c;
}

// card styles
.card {
  background: #ffffff;
  /* Primary/Card Small */
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  border-color: transparent;

  .card-caption {
    font-weight: bold;
    font-size: 20px;
    color: #262626;
    margin-top: 10px;
    margin-bottom: 0.5rem;
  }

  .card-header {
    border-bottom: 1px solid #e5e5e559;
    background: transparent;
    padding-top: 15px;
    padding-bottom: 0;
    margin-bottom: 15px;
  }
}

.card-caption {
  font-weight: bold;
  font-size: 22px;
  color: #262626;
  margin-top: 10px;
  margin-bottom: 20px;
}

.card-sub-caption {
  font-size: 14px;
}

.card-bg-theme {
  background: linear-gradient(0deg, #f7636c, #f7636c);
}

.title-color {
  color: #f7fbf4;
}

.header-card {
  .inner-card {
    background: #ffffff;
    border-radius: 12px;
    padding: 10px 20px;
  }
}

.plan-card {
  background: transparent;
  border: 1px solid transparent;

  .long-desc {
    color: rgb(133, 130, 130);
    margin-top: 15px;
    font-size: 14px;
    display: none;
  }

  .learn-more {
    color: rgb(133, 130, 130);
    margin-top: 15px;
    margin-bottom: 1rem !important;
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;
    // border-color: #2856ce;
    background: #fff;
    border: 1px solid rgba(0, 0, 21, 0.125);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;

    .long-desc {
      display: inline-block;
      margin-bottom: 1rem !important;
    }

    .learn-more {
      display: none;
    }
  }
}

// new
.item-card {
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  cursor: pointer;

  .card-image-wrapper {
    background: #f9fbfc;
    // opacity: 0.5;
    /* Medium Grey */

    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px 0;
    margin: 15px;
  }

  .card-body {
    padding-bottom: 0;
    padding: 0 15px 15px 15px;
  }

  .card-text {
    font-size: 12px;
    color: #4d4d4d;
  }

  .card-title {
    font-size: 13px;
    font-weight: 600;
    color: #4d4d4d;
  }

  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;

    .long-desc {
      display: block;
    }

    .learn-more {
      display: none;
    }
  }

  .long-desc {
    color: rgb(133, 130, 130);
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 1rem;
    display: none;
  }

  .learn-more {
    color: rgb(133, 130, 130);
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 1rem;
  }
}

.count-card {
  background: #ffffff;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.08);
  border-radius: 12px;

  .text-red {
    color: #f7636c;
  }

  .text-green {
    color: #4abf8a;
  }

  .text-blue {
    color: #407bff;
  }

  .status-text {
    font-size: 18px;
    font-weight: 600;
  }

  .synced {
    color: #4abf8a;
  }

  .inprogress {
    color: #407bff;
  }
}

.count-block {}

.selection-card {
  border: 1px solid rgba(0, 0, 21, 0.125);
}

.selection-card.card-active {
  background: #2856ce0a;
  border: 0.5px solid #f1626b;
}

.card-disabled {
  opacity: 0.4;

  &:hover {
    cursor: not-allowed;
  }
}

.card-active {
  background-color: #f1626b14;
  border: 0.5px solid #f1626b;

  .card-image-wrapper {
    background: #ffffff;
    // box-shadow: 0px 0px 6px rgba(0, 67, 37, 0.05), inset 0px 0px 12px rgba(0, 0, 0, 0.05);
  }

  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }
}

.info-card {
  // background: none;
  // border: none;
}

.display-card {
  min-height: 225px;

  &:hover {
    box-shadow: 0px 6px 14px rgb(0 0 0 / 8%);
    cursor: default;
  }
}

.details-card {
  background: #f3f3f3;
  border-radius: 12px;
  box-shadow: none;

  .card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 8px;
  }

  .card-body {
    padding: 0.5rem 1rem;
  }

  p {
    font-size: 14px;
  }

  &:hover {
    box-shadow: none !important;
  }
}

// input box shadow removed
.form-control {
  border: 0.5px solid #e5e5e5b6;
  border-radius: 12px;
  padding: 8px 10px;

  &:focus {
    box-shadow: none;
    border: 0.001px solid #848ed3;
  }
}

//progress bar
.cust-progress {
  background: #F9F9FD;
  /* Lime Green */

  box-sizing: border-box;
  border-radius: 8px;

  height: 10px;

  .progress-bar {
    background: linear-gradient(90deg, #f7636c 0%, #639ef7 100%);
    border-radius: 8px;
  }
}

// step lable
.step-info {
  color: #4d4d4d;

  span {
    font-weight: bold;
    font-size: 14px;
  }

  font-size: 14px;
}

// step dropdown
.cust-step-selector-dropdown {
  padding: 0 0 3px 0;
  

  button {
    border: none;

    box-shadow: none;

    &:focus {
      box-shadow: none;
      border: none;
    }

    &:active {
      box-shadow: none;
      border: none;
    }
  }

  button:active:focus {
    box-shadow: none;
    border: none;
  }

  li {
    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-menu{
    font-size: 14px;
  }
}

// transition
/* appear - on page load */
.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

/* enter */
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 5000ms linear 5000ms;
}

/* exit */
.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 5000ms linear;
}

.fade-exit-done {
  opacity: 0;
}

// spinner wrapper
.cust-spinner-wrapper {
  width: 100%;

  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

//buttons

// project card dashboard
.project-card {
  table {
    font-size: 14px;

    td {
      padding: 0 10px 5px 0;
    }

    tr {
      :first-child {
        color: #f7636c;
        padding-right: 20px;
        font-weight: 600;
      }
    }
  }

  .btn.text-red {
    width: 90%;

    color: #f7636c;

    &:hover {
      color: #fff;
    }
  }

  .card-image-wrapper {
    min-height: 150px;
  }
}

.pro-status {
  font-size: 14px;
  font-weight: normal;

  div {
    border: 1px solid black;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    display: inline-block;
  }

  .running {
    border-color: #18af18;
    background-color: #18af18;
  }

  .processing {
    border-color: #edd60d;
    background-color: #edd60d;
  }

  .terminated {
    border-color: #ed0d0d;
    background-color: #ed0d0d;
  }
}

.c-datalist {
  display: inline-flex;

  width: 100%;
  justify-content: space-between;
}

.fb-login-btn {
  background-color: #4c69ba;
  border-color: #4c69ba;

  img {
    margin-right: 5px;
  }
}

.google-login-btn {
  background-color: #d63731;
  border-color: #d63731;

  img {
    margin-right: 5px;
  }
}

.github-login-btn {
  background-color: #333333;
  border-color: #333333;

  img {
    margin-right: 5px;
  }
}

.label-text {
  font-size: 16px;
  font-weight: 600;
  color: #262626;
  margin-bottom: 0.8rem;
}

input[type='range'] {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
}

.ssh-text {
  background-color: #f3f3f3;
  padding: 10px;
  color: #4d4d4d;
  border-radius: 12px;
  font-size: 14px;
  margin-top: 15px;

  span {
    color: #f7636c;
  }

  .icon-clone {
    background-color: #ffffff;

    border-radius: 50%;
    color: #000000;

    padding: 8px;
    line-height: 1;
  }
}

.pointer {
  cursor: pointer;
}

.history-modal {
  ul {
    padding: 0;
  }

  li {
    word-break: break-all;
    list-style-type: none;
  }
}

.stripe-payment {

  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 1px solid #b1b7c1;
    outline: 0;
    border-radius: 12px;
    background: white;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;

    border: 0.002px solid #f7636c;
  }
}

.plus-icon {
  height: 50px !important;
  width: 50px !important;
  background: #f9fbfc;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
}

.dropdown-menu {
  border-radius: 12px;

  .dropdown-header {
    border-radius: 12px 12px 0 0;
  }

  a {
    &:active {
      background-color: #f7636c;
    }

    &:hover {
      background-color: #f1626b14;
    }
   
  }

  :last-child {
    a {
      border-radius: 0 0 10px 10px;
    }
  }
  .dropdown-item.active{
   
      background-color: #f7636c ;
    
  }
}

.next-icon {
  margin-left: 5px;
}

.prev-icon {
  margin-right: 5px;
}

.modal-header {
  background-color: #f7636c;
  color: #fff;
  border-radius: 12px 12px 0 0;
}

.modal-content {
  border-radius: 12px;
  border: 0.002px solid #f7636c;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 67, 37, 0.05), inset 0px 0px 12px rgba(0, 0, 0, 0.05);
}

.price-text {
  color: #f7636c !important;
  font-weight: 600;
}

// table.cust-table,
// tr:first-child {
//   border-radius: 12px 12px 0 0;
// }

.cust-table {
  font-size: 14px;
  text-align: left;

  thead {
    background: linear-gradient(0deg, #f7636c, #f7636c);
    /* Dropshadow 5% Black */

    box-shadow: 0px 14px 56px rgba(0, 0, 0, 0.05);

    th {
      border-color: #f7636c;
      color: #fff;
    }
  }

  .pro-status {
    font-size: 14px;
  }
}

.updated-time {
  font-weight: normal;
  margin-right: 10px;
  font-size: 14px;
}

.dash-card {
  background-image: url('../assets/images/dashboard-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.pro-percent{
  background: #FFFFFF;
/* Dark/Card 8% Black 14 Blur */

box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
height: 30px;
width: 30px;
border-radius: 50%;
position: absolute;
line-height: 2.5;
span{
  background: linear-gradient(90deg, #F7636C 0%, #639EF7 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;

font-weight: 800;
font-size: 10px;

}


}
.simplebar-scrollbar:before{
  background: none !important;
}

// radio
input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #f7636c;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #f7636c;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #f7636c;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:checked{

  background: #fff;
 border-color: #f7636c;
 outline: none;
 box-shadow: none;
}
.form-check{
  line-height: 1;
}

.form-switch{
input[type="checkbox"]:checked {
  border-color: #f7636c;
  background-color: #f7636c;
}
}

.filter-form{
  .form-control,.form-select{
    border: 1px solid #d8dbe0;
    border-radius: 5px;
    height: 35px;
    font-size: 14px;

    &:focus{
      outline: none;
      box-shadow: none;
    }
  }
  textarea{
    height: auto !important;
  }

}
.modal-info-text{
  font-size: 14px;
  margin-bottom: 14px;
}

// for new extra cards // request for services

.item-card.custom-card {
  cursor: pointer;
  overflow: hidden;
  border: 0;
}
.item-card.custom-card .card-body {
  padding: 0;
  border: 0;
}
.item-card.custom-card   img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  object-fit: cover;
  height: 100%;
  right: 0;
  margin: 0 !important;
}

